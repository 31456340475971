<template>
  <div class="profile-main">
    <div
      class="d-flex justify-content-center align-items-center h-100"
      v-if="isLoading"
    >
      <b-spinner variant="success" label="Spinning"></b-spinner>
    </div>

    <div class="header-title">Profile</div>

    <b-card no-body id="content">
      <b-tabs card lazy>
        <b-tab active title-link-class="tab">
          <template v-slot:title>
            <div class="d-flex flex-row justify-content-center">
              <inline-svg
                :src="require('../assets/icon/setting.svg')"
                width="25"
                aria-label="setting"
              ></inline-svg>
              <span class="tab-title">Setting</span>
            </div>
          </template>

          <div class="profile-body" v-if="!isLoading">
            <form id="profile-form">
              <div class="form-group row">
                <label for="email" class="col-sm-3">Email:</label>
                <div class="col-sm-9 input-div justify-content-center">
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    :value="profile.email"
                    readonly
                  />
                  <!-- <span>{{ profile.email }}</span> -->
                </div>
              </div>

              <div class="form-group row">
                <label for="password" class="col-sm-3">Password:</label>
                <!-- <div class="col-sm-9 input-div">
                                    <input type="password" class="form-control" id="password" value="password" readonly>
                                    <b-button class="edit-btn" @click="showPWModal">
                                        <inline-svg :src="require('../assets/icon/edit.svg')" aria-label="swap" stroke="var(--primary)"
                                            id="edit"></inline-svg>
                                    </b-button>
                                </div> -->
                <div class="input-div col-sm-9 position-relative">
                  <div class="col-12 p-0">
                    <input
                      type="password"
                      class="form-control text-center border-radius"
                      id="password"
                      value="example-value"
                      disabled
                    />
                  </div>
                  <div class="text-right edit-icon">
                    <inline-svg
                      type="button"
                      :src="require('../assets/icon/edit.svg')"
                      aria-label="swap"
                      stroke="var(--primary)"
                      id="edit"
                      @click="showPWModal"
                      width="20"
                      height="38"
                    ></inline-svg>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="password" class="col-sm-3">Api Key:</label>
                <div class="col-sm-9">
                  <b-button
                    class="active-outline-btn"
                    v-if="profile.pub_key == null"
                    @click="showChangeApikeyModal"
                  >
                    Generate Key Pair
                  </b-button>
                  <div class="input-div" v-else>
                    <input
                      type="text"
                      class="form-control"
                      :value="`${profile?.pub_key?.substr(
                        0,
                        8
                      )}.....${profile?.pub_key?.substr(-8, 8)}`"
                      readonly
                    />
                    <div class="tooltip__layout position-absolute right-40">
                      <button
                        type="button"
                        class="icon-btn mr-3"
                        @click="showChangeApikeyModal"
                      >
                        <b-icon-arrow-repeat></b-icon-arrow-repeat>
                      </button>
                      <button
                        type="button"
                        class="icon-btn"
                        @click="copyToClip(profile.pub_key, 'apiKey')"
                      >
                        <inline-svg
                          :src="require('../assets/icon/copy.svg')"
                          width="18"
                          aria-label="copy"
                        ></inline-svg>
                        <span class="tooltip__content" id="apiKey"
                          >Copied!</span
                        >
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="form-group row">
                                <label for="password" class="col-sm-3">Api Key:</label>
                                <div class="col-sm-9 d-flex">
                                    <div class="input-div col-10 px-0 pr-2">
                                        <input type="text" class="form-control"
                                            :value="`${profile?.api_key?.substr(0, 8)}.....${profile?.api_key?.substr(-8, 8)}`"
                                            readonly>
                                        <div class="tooltip__layout position-absolute right-40">
                                            <button type="button" class="icon-btn"
                                                @click="copyToClip(profile.api_key, 'apiKey')">
                                                <inline-svg :src="require('../assets/icon/copy.svg')" width="18"
                                                    aria-label="copy"></inline-svg>
                                                <span class="tooltip__content" id="apiKey">Copied!</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <button type="button" class="btn btn-warning text-white px-2"
                                            @click="showChangeApikeyModal">
                                            Change
                                        </button>
                                    </div>
                                </div>
                            </div> -->

              <!-- <div class="form-group row">
                                <label for="password" class="col-sm-3">Trading Pin:</label>
                                <div class="col-sm-9 input-div">
                                    <input type="password" class="form-control" id="trading_pin" value="password" readonly>
                                    <b-button class="edit-btn" @click="showPinModal">
                                        <inline-svg
                                            :src="require('../assets/icon/edit.svg')"
                                            aria-label="swap"
                                            id="edit"
                                        ></inline-svg>
                                    </b-button>
                                </div>
                            </div> -->

              <!--
                            <div class="form-group row">
                                <label for="api" class="col-sm-3">API Key:</label>
                                <div class="col-sm-9 input-div">
                                    <input type="password" class="form-control" id="api" value="password">
                                    <b-button class="edit-btn" @click="showAPIModal">
                                        <inline-svg
                                            :src="require('../assets/icon/edit.svg')"
                                            aria-label="swap"
                                            id="edit"
                                        ></inline-svg>
                                    </b-button>
                                </div>
                            </div> -->

              <div class="form-group row">
                <label for="endpoint" class="col-sm-3">End Point:</label>
                <div class="input-div col-sm-9 position-relative">
                  <div class="col-12 p-0">
                    <input
                      type="text"
                      class="form-control border-radius"
                      id="endpoint"
                      v-model="profile.callback_url"
                      disabled
                    />
                  </div>
                  <!-- <div class="col-2 text-right bg-white border-right-radius">
                    <inline-svg
                      type="button"
                      :src="require('../assets/icon/edit.svg')"
                      aria-label="swap"
                      stroke="var(--primary)"
                      id="edit"
                      @click="showEndPointModal"
                      width="20"
                      height="38"
                    ></inline-svg>
                  </div> -->

                  <div class="text-right edit-icon">
                    <inline-svg
                      type="button"
                      :src="require('../assets/icon/edit.svg')"
                      aria-label="swap"
                      stroke="var(--primary)"
                      id="edit"
                      @click="showEndPointModal"
                      width="20"
                      height="38"
                    ></inline-svg>
                  </div>
                </div>
              </div>

              <div class="form-group row" v-if="isRoot">
                <label for="password" class="col-sm-3">IP Whitelist:</label>
                <!-- <div class="col-sm-9 input-div">
                                    <input type="text" class="form-control" id="endpoint"
                                        v-model="profile.ip_whitelist" disabled>
                                    <b-button class="edit-btn" @click="showWhitelistModal">
                                        <inline-svg :src="require('../assets/icon/edit.svg')" aria-label="swap" stroke="var(--primary)"
                                            id="edit"></inline-svg>
                                    </b-button>
                                </div> -->
                <div class="input-div col-sm-9 position-relative">
                  <div class="col-12 p-0">
                    <input
                      type="text"
                      class="form-control border-radius"
                      v-model="profile.ip_whitelist"
                      disabled
                    />
                  </div>
                  <div class="text-right border-radius edit-icon">
                    <inline-svg
                      type="button"
                      :src="require('../assets/icon/edit.svg')"
                      aria-label="swap"
                      stroke="var(--primary)"
                      id="edit"
                      @click="showWhitelistModal"
                      width="20"
                      height="38"
                    ></inline-svg>
                  </div>
                </div>
              </div>

              <!-- <div class="form-group row">
                                <label for="endpoint" class="col-sm-3">Api Docs:</label>
                                <div class="col-sm-9 input-div">
                                    <b-button v-if="!profile.swagger" class="w-100 activate-btn text-center"
                                        @click="getSwagger">Get it</b-button>
                                    <a v-else :href="profile.swagger" target="_blank"
                                        class="activate-btn text-center text-decoration-none w-100">View Docs</a>
                                </div>
                            </div> -->

              <div class="form-group mt-3 row">
                <label for="twoFaSecretCode" class="col-sm-3">2FA:</label>
                <div class="col-sm-9 px-0 input-div d-flex align-items-center">
                  <!-- <input type="text" class="form-control" id="googleFA" placeholder="Enter 2-FA Code"> -->
                  <!-- <PincodeInput v-model="twoFaCode" :length=6 /> -->
                  <div class="col-sm-12" v-if="fa_activate">
                    <button
                      type="button"
                      v-b-modal.unbind-twofa-modal
                      class="inactive-outline-btn"
                    >
                      Unbind 2FA
                    </button>
                  </div>

                  <div class="col-sm-12" v-else>
                    <button
                      type="button"
                      v-b-modal.bind-twofa-modal
                      class="active-outline-btn btn"
                    >
                      Bind 2FA
                    </button>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="email" class="col-sm-3">GAS pay by:</label>
                <div class="col-sm-9 input-div justify-content-center">
                  <!-- <input type="email" class="form-control" id="email" v-model="profile.email" readonly> -->
                  <input
                    class="form-control"
                    type="text"
                    :value="profile.charge_usd ? 'USDT' : 'NATIVE'"
                    readonly
                  />
                  <!-- <span>{{ profile.charge_usd ? 'USDT' : 'NATIVE' }}</span> -->
                </div>
              </div>

              <!-- 
                            <div class="form-group row">
                                <div class="col-sm-3"></div>
                                <div class="col-sm-9 mt-4" v-if="fa_activate">
                                    <button type="button" @click="unbind2FA" class="deactivate-btn">Unbind 2FA</button>
                                </div>

                                <div class="col-sm-9 mt-4" v-else>
                                    <button type="button" @click="bind2FA" class="activate-btn">Bind 2FA</button>
                                </div>
                            </div> -->
            </form>
          </div>
        </b-tab>

        <!-- <b-tab>
                    <template v-slot:title>
                        <div class="d-flex flex-row justify-content-center">
                            <inline-svg :src="require('../assets/icon/coin-setting.svg')" width="25"
                                aria-label="coin"></inline-svg>

                            <span class="tab-title">Coin Setting</span>
                        </div>
                    </template>

                    <div class="coin-setting-body" v-if="!isLoading">
                        <b-table :items="tokens" :per-page="perPage" :current-page="currentPage" :fields="coinFields"
                            show-empty small responsive empty-filtered-text="No Data" class="w-100 mt-3"
                            id="coin-setting-table">
                            <template #cell(token)="row">
                                <div class="p-2 d-flex flex-row w-100 coin-setting-div">
                                    <div>
                                        <img :src="row.item.token.image" width=50 height=50 />
                                    </div>
                                    <div class="d-flex flex-column justify-content-center coin">
                                        <div><strong>{{ row.item.token.name }}</strong></div>
                                        <div class="text-gray small">
                                            {{ row.item.token.api_symbol }}
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template #cell(threshold)="row">
                                {{ addCommas(formatNumber(row.item.threshold,
                                row.item.token.decimal).toFixed(row.item.token.volume_decimal)) }}
                            </template>

                            <template #cell(deposit_fee)="row">
                                {{ row.item.deposit_fee_rate * 100 }}%
                            </template>

                            <template #cell(withdraw_fee)="row">
                                {{ row.item.withdraw_fee_rate * 100 }}%
                            </template>

                            <template #cell(fixed_fee)="row">
                                {{ addCommas(formatNumber(row.item.fixed_fee,
                                row.item.token.decimal).toFixed(row.item.token.volume_decimal)) }}
                            </template>

                            <template #cell(status)="row">
                                <span class="table-status"
                                    :style="(row.item.status == 'active') ? 'color: var(--primary)' : 'color: var(--danger)'">
                                    {{ row.item.status }}
                                </span>
                            </template>

                            <template #cell(edit)="row">
                                <b-button class="edit" @click="updateThresholdModal(row.item.token.api_symbol)">
                                    <inline-svg :src="require('../assets/icon/edit.svg')" aria-label="swap"
                                        id="edit"></inline-svg>
                                </b-button>
                            </template>

                            <template #empty="scope">
                                <p class="text-center">{{ (scope.emptyText = "No Data") }}</p>
                            </template>
                        </b-table>

                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="10"
                            aria-controls="coin-setting-table" align="center"></b-pagination>
                    </div>
                </b-tab> -->
      </b-tabs>
    </b-card>

    <!-- Modal -->
    <!-- PW Modal -->
    <b-modal ref="pw-modal" hide-footer title="RESET PASSWORD" centered>
      <div class="d-flex flex-column text-center">
        <form
          id="pw-form"
          class="modal-form"
          @submit.stop.prevent="resetPassword"
        >
          <div class="form-group">
            <label for="" class="">New Password</label>
            <b-form-input
              id="example-input-1"
              type="password"
              placeholder="Enter new password"
              v-model="$v.resetPasswordForm.new_password.$model"
              :state="validateState('new_password', 'resetPasswordForm')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback"
              >This is a required field
            </b-form-invalid-feedback>

            <label for="" class="mt-4">Confirm Password</label>
            <b-form-input
              id="example-input-1"
              type="password"
              placeholder="Confirm password"
              v-model="$v.resetPasswordForm.confirm_password.$model"
              :state="validateState('confirm_password', 'resetPasswordForm')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <label class="mt-4">2FA</label>
              <!-- <PincodeInput
                                v-model="changePassword2FA"
                                :length=6
                            /> -->
              <v-otp-input
                ref="otpInput"
                input-classes="otp-input"
                separator=""
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                v-model="changeApiKey2Fa"
                input-type="number"
                @on-complete="changePassword2FaComplete"
              />
            </div>

            <b-form-invalid-feedback
              id="input-1-live-feedback"
              v-if="!$v.resetPasswordForm.sameAsPassword"
              >Passwords must be same.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback id="input-1-live-feedback" v-else
              >This is a required field
            </b-form-invalid-feedback>
          </div>

          <div class="form-group">
            <div class="mt-5 mb-5 w-100">
              <button type="submit" class="submit-btn">Reset Password</button>
            </div>
          </div>
        </form>
      </div>
    </b-modal>

    <!-- Trading Pin Modal -->
    <b-modal ref="pin-modal" hide-footer title="RESET TRADING PIN" centered>
      <div class="d-flex flex-column text-center">
        <form id="pw-form" class="modal-form" @submit.stop.prevent="resetPin">
          <div class="form-group">
            <label for="" class="">New Trading Pin</label>
            <b-form-input
              id="example-input-1"
              placeholder="Enter new trading pin"
              v-model="$v.resetPinForm.new_pin.$model"
              :state="validateState('new_pin', 'resetPinForm')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback"
              >This is a required field
            </b-form-invalid-feedback>

            <label for="" class="mt-4">Confirm Trading Pin</label>
            <b-form-input
              id="example-input-1"
              placeholder="Confirm trading pin"
              v-model="$v.resetPinForm.confirm_pin.$model"
              :state="validateState('confirm_pin', 'resetPinForm')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback
              id="input-1-live-feedback"
              v-if="!$v.resetPinForm.sameAsPin"
              >Trading pin must be same.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback id="input-1-live-feedback" v-else
              >This is a required field
            </b-form-invalid-feedback>
          </div>

          <div class="form-group">
            <div class="mt-5 mb-5 w-100">
              <button type="submit" class="submit-btn">
                Reset Trading Pin
              </button>
            </div>
          </div>
        </form>
      </div>
    </b-modal>

    <!-- Api Modal -->
    <b-modal ref="api-modal" hide-footer title="CHANGE TRADING PIN" centered>
      <div class="d-flex flex-column text-center">
        <form id="modal-form">
          <div class="form-group">
            <label for="" class="">New Trading Pin</label>
            <div>
              <input
                type="text"
                class="form-control"
                id="newAPI"
                placeholder="Enter New Trading Pin"
              />
            </div>
          </div>

          <div class="form-group">
            <label for="" class="">2-FA Code</label>
            <div>
              <input
                type="text"
                class="form-control"
                id="twoFaSecretCode"
                placeholder="Enter 2-FA Code"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="mt-5 mb-5 w-100">
              <button type="submit" class="submit-btn">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </b-modal>

    <!-- End Point Modal -->
    <b-modal ref="endPoint-modal" hide-footer title="SET END POINT" centered>
      <div class="d-flex flex-column align-items-center text-center">
        <form
          id="endpoint-form"
          class="modal-form"
          @submit.stop.prevent="setCallBack"
        >
          <div class="form-group">
            <label for="" class="">End Point</label>
            <b-form-input
              id="example-input-1"
              placeholder="Enter new end point"
              v-model="$v.callbackForm.callback.$model"
              :state="validateState('callback', 'callbackForm')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback"
              >This is a required field
            </b-form-invalid-feedback>

            <label for="" class="mt-4">2FA</label>
            <!-- <b-form-input id="example-input-1" placeholder="Enter password"
                            v-model="$v.callbackForm.password.$model" :state="validateState('password', 'callbackForm')"
                            aria-describedby="input-1-live-feedback"></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">This is a required field
                        </b-form-invalid-feedback> -->
            <v-otp-input
              ref="endPointTwoFa"
              input-classes="otp-input"
              separator=""
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              nput-type="number"
              @on-complete="setEndPoint2FAComplete"
            />
          </div>

          <div class="form-group">
            <div class="mt-5 mb-5 w-100">
              <button type="submit" class="submit-btn">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </b-modal>

    <!-- Update Threshold Modal -->
    <b-modal
      ref="threshold-modal"
      hide-footer
      :title="'EDIT ' + updateThresholdForm.api_symbol + ' THRESHOLD'"
      centered
    >
      <div class="d-flex flex-column text-center">
        <form
          id="update-threshold-form"
          class="modal-form"
          @submit.stop.prevent="updateThreshold"
        >
          <div class="form-group">
            <b-form-input
              id="example-input-1"
              placeholder="Enter threshold"
              v-model="$v.updateThresholdForm.threshold.$model"
              :state="validateState('threshold', 'updateThresholdForm')"
              aria-describedby="input-1-live-feedback"
              type="number"
            ></b-form-input>
            <b-form-invalid-feedback id="input-1-live-feedback"
              >This is a required field
            </b-form-invalid-feedback>
          </div>

          <div class="form-group">
            <div class="mt-5 mb-5 w-100">
              <button type="submit" class="submit-btn">Edit</button>
            </div>
          </div>
        </form>
      </div>
    </b-modal>

    <!--Bind 2FA Modal -->
    <b-modal id="bind-twofa-modal" hide-footer title="Bind 2FA" centered>
      <div class="border d-flex flex-column align-items-center p-2">
        <!-- QR -->
        <div class="qr-code">
          <vue-qr :text="twoFaUrl" :size="200"></vue-qr>
        </div>

        <div class="modal-wallet-address">
          <span class="pr-3">
            {{ twoFaSecretCode }}
          </span>
        </div>
      </div>

      <div class="d-flex flex-column align-items-center mt-3">
        <p>Verification code</p>
        <v-otp-input
          ref="bind2FAOtp"
          input-classes="otp-input"
          separator=""
          :num-inputs="6"
          :should-auto-focus="true"
          :is-input-num="true"
          input-type="number"
          @on-complete="bind2FAOtpComplete"
        />
        <button class="active-outline-btn btn w-50 mt-5" @click="bind2FA">
          Bind
        </button>
      </div>
    </b-modal>

    <!--Unbind 2FA Modal -->
    <b-modal id="unbind-twofa-modal" hide-footer title="Unbind 2FA" centered>
      <div class="d-flex flex-column align-items-center mt-3">
        <p>Verification code</p>
        <v-otp-input
          ref="unbind2FAOtp"
          input-classes="otp-input"
          separator=""
          :num-inputs="6"
          :should-auto-focus="true"
          :is-input-num="true"
          input-type="number"
          @on-complete="unbind2FAOtpComplete"
        />
        <button class="inactive-outline-btn w-50 mt-5" @click="unbind2FA">
          Unbind
        </button>
      </div>
    </b-modal>

    <!--Change ApiKey Modal -->
    <b-modal
      ref="change-apikey-modal"
      id="change-apikey-modal"
      hide-footer
      title="Generate Key Pair"
      centered
    >
      <div class="d-flex flex-column align-items-center">
        <p>Please key in 2fa to continue the action</p>
        <!-- <PincodeInput
                    v-model="changeApiKey2Fa"
                    :length=6
                /> -->
        <v-otp-input
          ref="keyPairOtp"
          input-classes="otp-input"
          separator=""
          :num-inputs="6"
          :should-auto-focus="true"
          :is-input-num="true"
          v-model="changeApiKey2Fa"
          input-type="number"
          @on-complete="changeApiKey2FaComplete"
        />
      </div>
      <div class="mt-5 text-center">
        <button
          type="button"
          @click="changeApiKey"
          class="activate-btn btn w-50"
        >
          Confirm
        </button>
      </div>
    </b-modal>

    <!--Change Whitelist Modal -->
    <b-modal
      ref="change-whitelist-modal"
      id="change-whitelist-modal"
      hide-footer
      title="Add Whitelist IP"
      centered
    >
      <div class="d-flex flex-column align-items-center">
        <b-form-input
          id="example-input-10"
          placeholder="Enter whitelist ip"
          v-model="$v.whitelistIpForm.ip.$model"
          :state="validateState('ip', 'whitelistIpForm')"
          aria-describedby="input-10-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-10-live-feedback"
          >This is a required field
        </b-form-invalid-feedback>
      </div>

      <div class="d-flex flex-wrap mt-3">
        <div
          class="col-12 col-lg-6 form-group"
          v-for="(ip, index) in splitWhitelist"
          :key="`ip${index}}`"
        >
          <div
            class="activate-btn px-3 d-flex justify-content-between align-items-center"
          >
            <span>{{ ip }}</span>
            <b-icon
              icon="x-lg"
              variant="danger"
              type="button"
              @click="deleteIp(ip)"
            ></b-icon>
          </div>
        </div>
      </div>

      <!-- <div class="d-flex flex-column align-items-center justify-content-center">
                <label for="" class="mt-4">2FA</label>
                <v-otp-input ref="endPointTwoFa" input-classes="otp-input" separator="" :num-inputs="6"
                    :should-auto-focus="true" :is-input-num="true" nput-type="number"
                    @on-complete="changeWhitelist2FAComplete" />
            </div> -->

      <button
        type="button"
        @click="updateWhitelistIp"
        class="activate-btn mt-3"
      >
        Add
      </button>
    </b-modal>

    <!-- Show Api Key Modal -->
    <b-modal
      ref="show-key-modal"
      id="show-key-modal"
      title="API KEY"
      hide-footer
      centered
      active
    >
      <div>
        <p>
          Savvixpay requires that you store your private key in a safe place. It
          is essential in using Savvixpay API. We recommend you write it down.
        </p>

        <div class="card p-2">
          <div class="mb-3">
            <label class="mb-1" for="public-key">Public Key :</label>
            <small class="text-break">{{ publicKey }}</small>
          </div>
          <div class="mb-3">
            <label class="mb-1" for="private-key"
              >Private Key (will only show once) :</label
            >
            <small class="text-break">{{ privateKey }}</small>
          </div>
          <small class="text-danger mb-2"
            ><b
              >* Note: If you lose your private key, Savvixpay can’t help you
              recover your private key. You will need to regenerate the API key
              pair in your profile.</b
            >
          </small>
          <small class="text-danger"
            ><b
              >* Never share your private key to anyone or any site, unless you
              want them to have full control over your funds.</b
            ></small
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators";
import api from "../helper/api.js";
import InlineSvg from "vue-inline-svg";

import VueQr from "vue-qr";
// import PincodeInput from 'vue-pincode-input';
// import axios from 'axios';

export default {
  name: "Profile",
  components: {
    InlineSvg,
    VueQr,
    // PincodeInput
  },

  data() {
    return {
      isRoot: localStorage.getItem("root"),
      fa_activate: false,
      tokens: [],
      token_one: [],
      isLoading: false,
      coinFields: [
        { key: "token", label: "" },
        { key: "threshold", label: "THRESHOLD" },
        { key: "deposit_fee", label: "DEPOSIT FEE RATE" },
        { key: "withdraw_fee", label: "WITHDRAW FEE RATE" },
        { key: "fixed_fee", label: "FIXED FEE" },
        { key: "status", label: "STATUS" },
        { key: "edit", label: "EDIT" },
      ],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      profile: {
        email: "",
        callback_url: "",
        username: "",
        swagger: null,
        charge_usd: null,
      },
      resetPasswordForm: {
        new_password: "",
        confirm_password: "",
      },
      resetPinForm: {
        new_pin: "",
        confirm_pin: "",
      },
      updateThresholdForm: {
        api_symbol: "",
        threshold: "",
      },
      callbackForm: {
        callback: "",
        twofa: "",
      },
      whitelistIpForm: {
        ip: "",
        twofa: "",
      },
      bind2FAForm: {
        twofa: "",
      },
      unbind2FAForm: {
        twofa: "",
      },
      twoFaSecretCode: null,
      twoFaUrl: null,
      twoFaCode: "",
      changeApiKey2Fa: "",
      changePassword2FA: "",
      splitWhitelist: null,
      publicKey: "",
      privateKey: "",
    };
  },

  validations: {
    resetPasswordForm: {
      new_password: {
        required,
      },
      confirm_password: {
        sameAsPassword: sameAs("new_password"),
        required,
      },
    },

    resetPinForm: {
      new_pin: {
        required,
      },
      confirm_pin: {
        sameAsPin: sameAs("new_pin"),
        required,
      },
    },

    updateThresholdForm: {
      threshold: {
        Number,
        required,
      },
    },

    callbackForm: {
      callback: {
        required,
      },
    },

    whitelistIpForm: {
      ip: {
        required,
      },
    },
  },

  async mounted() {
    this.isLoading = true;

    // await this.fetchAllToken();
    await this.getCallBack();
    if (!this.fa_activate) {
      await this.get2FACode();
    }

    this.isLoading = false;
  },

  methods: {
    showPWModal() {
      if (!this.fa_activate) {
        this.$swal({
          text: "Please set up 2FA first",
          icon: "error",
        });
        return;
      }
      this.$refs["pw-modal"].show();
    },
    showPinModal() {
      this.$refs["pin-modal"].show();
    },
    showAPIModal() {
      this.$refs["api-modal"].show();
    },
    showEndPointModal() {
      if (!this.fa_activate) {
        this.$swal({
          text: "Please set up 2FA first",
          icon: "error",
        });
        return;
      }

      this.$refs["endPoint-modal"].show();
    },

    showChangeApikeyModal() {
      if (!this.fa_activate) {
        this.$swal({
          text: "Please set up 2FA first",
          icon: "error",
        });
        return;
      }

      this.$refs["change-apikey-modal"].show();
    },

    showWhitelistModal() {
      if (!this.fa_activate) {
        this.$swal({
          text: "Please set up 2FA first",
          icon: "error",
        });
        return;
      }

      this.$refs["change-whitelist-modal"].show();
    },

    async updateThresholdModal(api_symbol) {
      this.$refs["threshold-modal"].show();

      try {
        const response = await api.apiCall(
          "trx",
          "GET",
          `/token-globals/get-one/${api_symbol}`
        );
        this.token_one = response.message.data;
        this.updateThresholdForm = {
          api_symbol: this.token_one.token.api_symbol,
          threshold: this.formatNumber(
            this.token_one.threshold,
            this.token_one.token.decimal
          ).toFixed(this.token_one.token.volume_decimal),
        };
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },

    validateState(name, form) {
      const { $dirty, $error } = this.$v[form][name];
      return $dirty ? !$error : null;
    },

    addCommas(nStr) {
      nStr += "";
      var x = nStr.split(".");
      var x1 = x[0];
      var x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },

    formatNumber(value, decimal) {
      return value / Math.pow(10, decimal);
    },

    async updateWhitelistIp() {
      this.$v.whitelistIpForm.$touch();
      if (this.$v.whitelistIpForm.$anyError) {
        return;
      }

      let twofa = prompt("Key in the 2FA in order to continue the action");

      if (!twofa) {
        return;
      }

      this.$swal({
        title: "Loading....",
        icon: "info",
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      try {
        let params = {
          whitelistIp: this.whitelistIpForm.ip,
          google2fa: twofa,
        };

        const response = await api.apiCall(
          "api",
          "POST",
          "/operators/whitelist-ip",
          params
        );

        if (response.status.toUpperCase() == "SUCCESS") {
          this.$swal({
            text: "Ip Whitelist updated successfully",
            icon: "success",
            confirmButtonColor: "#33AD4A",
          });

          this.getCallBack();
          // this.$refs['threshold-modal'].hide();
          // // await this.fetchAllToken();
          // // this.updateThresholdForm.threshold = "";
        }
      } catch (error) {
        this.$swal("Error", error.message, "error");
        console.error(error);
      }
    },

    async deleteIp(ip) {
      let twofa = prompt("Key in the 2FA in order to continue the action");

      if (!twofa) {
        return;
      }

      this.$swal({
        title: "Loading....",
        icon: "info",
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      try {
        let params = {
          whitelistIp: ip,
          google2fa: twofa,
        };

        const response = await api.apiCall(
          "api",
          "DELETE",
          "/operators/whitelist-ip",
          params
        );
        // const response = await axios.delete('https://test.savvixpay.com/api/operators/whitelist-ip',{
        //     headers : {
        //         Authorization : `Bearer ${localStorage.getItem('token')}`
        //     },
        //     data
        // })

        if (response.status.toUpperCase() == "SUCCESS") {
          this.$swal({
            text: "Delete ",
            icon: "success",
            confirmButtonColor: "#33AD4A",
          });

          // this.$refs['threshold-modal'].hide();
          // // await this.fetchAllToken();
          // // this.updateThresholdForm.threshold = "";
          this.getCallBack();
        }
      } catch (error) {
        this.$swal("Error", error.message, "error");
        console.error(error);
      }
    },

    changeApiKey2FaComplete(value) {
      this.changeApiKey2Fa = value;
    },

    changePassword2FaComplete(value) {
      this.changePassword2FA = value;
    },

    changeWhitelist2FAComplete(value) {
      this.whitelistIpForm.twofa = value;
    },

    async copyToClip(address, id, ref = null) {
      let refElement = this.$refs[ref];

      await this.$copyText(address, refElement).then(function () {
        var x = document.getElementById(id);
        x.classList.add("show");
        setTimeout(function () {
          x.classList.remove("show");
        }, 1000);
      });
    },

    async fetchAllToken() {
      try {
        let params = {
          order: "ASC",
          page: this.currentPage,
          take: 10,
        };

        const response = await api.apiCall(
          "core",
          "GET",
          "/token-globals/get-all",
          params
        );
        this.tokens = response.message.data;
        // this.isLoading = false;
        this.totalRows = response.message.meta.itemCount;
        this.currentPage = response.message.meta.page;
        this.perPage = this.tokens - 10 * this.currentPage;
      } catch (error) {
        console.error(error);
      }
    },

    async updateThreshold() {
      this.$v.updateThresholdForm.$touch();
      if (this.$v.updateThresholdForm.$anyError) {
        return;
      }

      const params = {
        api_symbol: this.updateThresholdForm.api_symbol,
        threshold: this.updateThresholdForm.threshold,
      };

      try {
        const response = await api.apiCall(
          "trx",
          "POST",
          "/token-globals/update-token",
          params
        );

        if (response.status.toUpperCase() == "SUCCESS") {
          this.$swal({
            text: "Threshold updated successfully",
            icon: "success",
            confirmButtonColor: "#33AD4A",
          });
          this.$refs["threshold-modal"].hide();
          await this.fetchAllToken();
          // this.updateThresholdForm.threshold = "";
        }
      } catch (error) {
        this.$swal("Error", error.message, "error");
        console.error(error);
      }
    },

    async resetPassword() {
      this.$v.resetPasswordForm.$touch();
      if (this.$v.resetPasswordForm.$anyError) {
        return;
      }

      if (!this.changePassword2FA) {
        this.$swal({
          text: "2FA code is required",
          icon: "error",
          // confirmButtonColor: '#33AD4A',
        });

        return;
      }

      const params = {
        password: this.resetPasswordForm.new_password,
        confirm_password: this.resetPasswordForm.confirm_password,
        google2fa: this.changePassword2FA,
      };

      try {
        const response = await api.apiCall(
          "core",
          "POST",
          "/operators/resetpassword",
          params
        );

        if (response.status.toUpperCase() == "SUCCESS") {
          this.$swal({
            text: "Password reset successfully",
            icon: "success",
            confirmButtonColor: "#33AD4A",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$refs["pw-modal"].hide();
              this.resetPasswordForm.new_password = "";
              this.resetPasswordForm.confirm_password = "";
            }
          });

          // this.updateThresholdForm.threshold = "";
        }
      } catch (error) {
        this.$swal("Error", error.message, "error");
        console.error(error);
      }
    },

    async resetPin() {
      this.$v.resetPinForm.$touch();
      if (this.$v.resetPinForm.$anyError) {
        return;
      }

      const params = {
        pin: this.resetPinForm.new_pin,
        confirm_pin: this.resetPinForm.confirm_pin,
      };

      try {
        const response = await api.apiCall(
          "core",
          "POST",
          "/operators/resetpin",
          params
        );

        if (response.status.toUpperCase() == "SUCCESS") {
          this.$swal({
            text: "Trading pin reset successfully",
            icon: "success",
            confirmButtonColor: "#33AD4A",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$refs["pin-modal"].hide();
              this.resetPinForm.new_pin = "";
              this.resetPinForm.confirm_pin = "";
            }
          });

          // this.updateThresholdForm.threshold = "";
        }
      } catch (error) {
        this.$swal("Error", error.message, "error");
        console.error(error);
      }
    },

    async getCallBack() {
      try {
        const response = await api.apiCall("core", "GET", "/operators");
        this.profile = {
          email: response.message.email,
          callback_url: response.message.callback_url,
          username: response.message.name,
          swagger: response.message.swagger,
          api_key: response.message.api_key,
          ip_whitelist: response.message.whitelist?.toString(),
          pub_key: response.message.pub_key,
          charge_usd: response.message.charge_usd,
        };
        this.splitWhitelist = this.profile.ip_whitelist?.split(",");
        this.fa_activate = response.message.google2fa;
      } catch (error) {
        console.error(error);
      }
    },

    async setCallBack() {
      console.log("hihi");
      this.$v.callbackForm.$touch();
      if (this.$v.callbackForm.$anyError) {
        console.log("form error");
        return;
      }

      if (this.callbackForm.twofa == null || this.callbackForm.twofa == "") {
        console.log("2fa error");
        this.$swal("Error", "2FA is required", "error");
      }

      const params = {
        call_back: this.callbackForm.callback,
        // "password": this.callbackForm.password,
        google2fa: this.callbackForm.twofa,
      };

      try {
        const response = await api.apiCall(
          "core",
          "POST",
          "/operators/setcallback",
          params
        );

        if (response.status.toUpperCase() == "SUCCESS") {
          this.$swal({
            text: "Callback url set successfully",
            icon: "success",
            confirmButtonColor: "#33AD4A",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$refs["endPoint-modal"].hide();
              this.callbackForm.callback = "";
              this.callbackForm.twofa = "";
              this.$refs.endPointTwoFa.clearInput();
            }
          });
          await this.getCallBack();

          // this.updateThresholdForm.threshold = "";
        }
      } catch (error) {
        this.$refs.endPointTwoFa.clearInput();
        this.$swal("Error", error.message, "error");
        console.log(error);
      }
    },

    async get2FACode() {
      try {
        const response = await api.apiCall("core", "GET", "/operators/secret");
        this.twoFaSecretCode = response.message.secret;
        this.twoFaUrl = `otpauth://totp/savvixpay:${this.profile.email}?secret=${this.twoFaSecretCode}&issuer=${this.profile.username}`;
      } catch (error) {
        console.error(error);
      }
    },

    async bind2FA() {
      let params = {
        secret: this.twoFaSecretCode,
        google2fa: this.bind2FAForm.twofa,
      };

      if (this.bind2FAForm.twofa == "" || this.bind2FAForm.twofa == null) {
        this.$swal({
          text: "Please fill in verification code",
          icon: "error",
        });
      }

      this.$swal({
        title: "Binding",
        icon: "info",
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      try {
        await api.apiCall("core", "POST", "/operators/bind2fa", params);
        // console.log(response);
        this.$swal({
          text: "Bind 2FA Success",
          icon: "success",
          confirmButtonColor: "#33AD4A",
        });
        localStorage.setItem("google2fa", "true");
        window.location.reload();
      } catch (error) {
        console.error(error);
        this.$refs.bind2FAOtp.clearInput();
        this.$swal({
          text: error.message,
          icon: "error",
        });
      }
    },
    async unbind2FA() {
      let params = {
        google2fa: this.unbind2FAForm.twofa,
      };

      if (this.unbind2FAForm.twofa == "" || this.unbind2FAForm.twofa == null) {
        this.$swal({
          text: "Please fill in verification code",
          icon: "error",
        });
      }

      this.$swal({
        title: "Unbinding",
        icon: "info",
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      try {
        await api.apiCall("core", "POST", "/operators/unbind2fa", params);
        // console.log(response);
        this.$swal({
          text: "Unbind 2FA Success",
          icon: "success",
          confirmButtonColor: "#33AD4A",
        });
        localStorage.setItem("google2fa", "null");
        window.location.reload();
      } catch (error) {
        console.error(error);
        this.$refs.unbind2FAOtp.clearInput();
        this.$swal({
          text: error.message,
          icon: "error",
        });
      }
    },

    async getSwagger() {
      let params = {};
      try {
        const response = await api.apiCall(
          "core",
          "POST",
          "/swagger-token",
          params
        );
        console.log(response);
        this.$swal({
          text: "Get swagger access success",
          icon: "success",
          confirmButtonColor: "#33AD4A",
        });
        this.getCallBack();
        // window.location.reload();
      } catch (error) {
        console.error(error);
      }
    },

    async changeApiKey() {
      if (!this.changeApiKey2Fa) {
        this.$swal({
          text: "2FA code is required",
          icon: "error",
          // confirmButtonColor: '#33AD4A',
        });

        return;
      }

      let params = {
        google2fa: this.changeApiKey2Fa,
      };
      try {
        const res = await api.apiCall("core", "POST", "/operators/gkp", params);
        console.log(res);
        // this.$swal({
        //     text: "Reset Api Key Success",
        //     icon: 'success',
        //     confirmButtonColor: '#33AD4A',
        // })

        this.publicKey = res.message.publicKey;
        this.privateKey = res.message.privateKey;

        this.$refs["change-apikey-modal"].hide();

        this.$refs["show-key-modal"].show();

        this.getCallBack();
        // window.location.reload();
      } catch (error) {
        console.error(error);
        this.$swal({
          text: error.message,
          icon: "error",
        });
        this.$refs["keyPairOtp"].clearInput();
      }
    },

    bind2FAOtpComplete(value) {
      this.bind2FAForm.twofa = value;
    },

    unbind2FAOtpComplete(value) {
      this.unbind2FAForm.twofa = value;
    },

    setEndPoint2FAComplete(value) {
      this.callbackForm.twofa = value;
    },
  },
};
</script>

<style>
@import "../css/styles.css";

.profile-main {
  height: 100%;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.profile-main::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.profile-main {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.profile-body {
  display: flex;
  justify-content: center;
  margin: 3% 8% 0% 8%;
}

#profile-form {
  width: 60%;
}

#profile-form label {
  color: var(--info);
  display: flex;
  align-items: center;
}

#profile-form .form-group input {
  background-color: var(--light);
  border: none;
  color: var(--dark);
  text-align: center;
  /* border-radius: 30px; */
  width: 100%;
}

.input-div {
  display: flex;
  position: relative;
  align-items: center;
}

.edit-btn {
  position: absolute;
  z-index: 1;
  right: 5%;
  color: lightslategray;
  border: none;
  background: transparent;
}

.edit-btn:hover path {
  stroke: var(--primary);
}

.FA_checkbox {
  right: 7%;
  position: absolute;
  color: gray;
}

.FA_checkbox .custom-checkbox-span {
  cursor: context-menu;
}

.deactivate-btn {
  width: 100%;
  background-color: var(--danger);
  padding: 8px;
  border: 1px solid transparent;
  border-radius: 30px;
  color: white;
}

.deactivate-btn:hover,
.deactivate-btn:focus {
  border: 1px solid var(--danger);
}

.activate-btn {
  width: 100%;
  background-color: transparent;
  padding: 8px;
  border: 1px solid var(--primary);
  border-radius: 30px;
  color: var(--primary);
}

.activate-btn:hover,
.activate-btn:focus {
  background-color: var(--primary-10);
  border: 1px solid var(--primary);
  color: var(--primary);
}

#googleFA-div {
  justify-content: space-evenly;
  width: fit-content;
}

/* Coin Setting */
.coin-setting-body {
  margin: 0% 10%;
}

.coin-setting-body > div {
  min-height: 60vh;
}

.min-send {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primary);
}

.min-send input {
  background-color: var(--info-10);
  border: none;
  color: var(--info);
  text-align: center;
  border-radius: 30px;
  width: 400px;
  padding: 8px;
}

.table-responsive {
  display: inline-table;
}

#coin-setting-table thead {
  border-bottom: 1px solid white;
}

#coin-setting-table th {
  border-top: none;
  text-align: center;
  /* color: #707070; */
  /* font-weight: normal; */
  white-space: nowrap;
  font-size: 14px;
}

#coin-setting-table tr {
  border-bottom: 1px solid white;
  /* color: black; */
  text-align: center;
}

#coin-setting-table td {
  border-top: none;
  vertical-align: middle !important;
}

.coin-setting-link,
.coin-setting-link:hover {
  border: none;
  background-color: transparent;
  color: white;
  width: 100%;
  text-decoration: none;
}

/* .coin-setting-td {
    width: 150px;
} */

.coin {
  align-items: baseline;
  margin-left: 15px;
  color: black;
  /* width: 60%; */
}

.table-status {
  text-transform: capitalize;
}

.edit {
  border: none;
  background: transparent;
}

.coin-setting-div {
  min-width: 200px;
}

@media only screen and (max-width: 768px) {
  .profile-body {
    margin: 0%;
  }

  #profile-form {
    width: 100%;
  }

  .coin-setting-body {
    margin: 0%;
  }

  .table-responsive {
    display: block;
  }

  .address {
    width: 200px;
  }
}

.input-div .right-40 {
  right: 40px;
}

.profile-main .edit-icon {
  position: absolute;
  top: 0;
  right: 35px;
}
</style>
